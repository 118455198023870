const getRequestParameters = () => {
  return window.location.search
    .replace('?', '')
    .split('&')
    .map(str => {
      return str.split('=')
    });
}

export function setUnsubscribe() {
  const data = getRequestParameters();
  const formData = new FormData();
  const xhr = new XMLHttpRequest();

  data.forEach(([key, value]) => {
    formData.append(key, value);
  });

  xhr.open('POST', 'https://altekloads.com/backend/api/loads/email-unsubscribe', false);

  try {
    xhr.send(formData);

    if (xhr.status !== 200) {
      console.log(xhr.status, xhr.statusText);
    } else {
      alert('Subscription canceled.')
    }
  } catch (e) {
    console.log(e);
  }
}
